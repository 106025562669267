import React, { useEffect, useState } from "react";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import DateObject from "react-date-object";
import NavBar from "../SideBar/NavBar";
import firebase from "../firebase";
import Footer from "./Footer";

import "../../style_components/FormStyle.css";
import "antd/dist/antd.css";

export default function AddVecHist() {
  const [vehicleHist, setVehicleHist] = useState({
    attDate: "",
    regoNumber: "",
    odoMetre: 0,
    wiperBlades: "",
    tyres: "",
    carBulb: "",
    handBrake: "",
    brakeDrumsOrPads: "",
    engineLight: "",
    oilMachine: "",
    oilFilter: "",
    fanOrCompressorBelts: "",
    radiator: "",
    battery: "",
    nextServiceDate: "",
    nextServiceOdometre: 0,
    errMsgNextOdo: "",
  });
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const database = firebase.firestore().collection("vehicles");

  function getVehicles() {
    let todayDate = new DateObject();
    todayDate = todayDate.format("YYYY-MM-DD");

    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicles(items);
      setLoading(false);
    });
    setVehicleHist((prevState) => ({ ...prevState, attDate: todayDate }));
  }

  useEffect(() => {
    getVehicles();
  }, []);

  function changeDate() {
    let date = new DateObject();
    return date.format("YYYY-MM-DD");
  }

  const setValue = (event) => {
    const {
      target: { name, value },
    } = event;
    setVehicleHist((prevState) => ({ ...prevState, [name]: value }));
  };

  const selectCondition = (event) => {
    const {
      target: { name, value },
    } = event;
    setVehicleHist((prevState) => ({ ...prevState, [name]: value }));
  };

  function selectOption(name, value) {
    if (name === "tyres" || name === "handBrake" || name === "carBulb") {
      return (
        <Form.Control
          as="select"
          required
          name={name}
          className="dropdown"
          onChange={selectCondition}
          value={value}
        >
          <option value="">Select Option</option>
          <option value="Good">Good</option>
          <option value="Needs Change (Front Left)">
            Needs Change (Front Left)
          </option>
          <option value="Needs Change (Front Right)">
            Needs Change (Front Right)
          </option>
          <option value="Needs Change (Rear Left)">
            Needs Change (Rear Left)
          </option>
          <option value="Needs Change (Rear Right)">
            Needs Change (Rear Right)
          </option>
        </Form.Control>
      );
    } else {
      return (
        <Form.Control
          as="select"
          required
          name={name}
          className="dropdown"
          onChange={selectCondition}
          value={value}
        >
          <option value="">Select Option</option>
          <option value="Good">Good</option>
          <option value="Needs Change">Needs Change</option>
        </Form.Control>
      );
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      parseInt(vehicleHist.odoMetre) >
        parseInt(vehicleHist.nextServiceOdometre) ||
      parseInt(vehicleHist.odoMetre) ===
        parseInt(vehicleHist.nextServiceOdometre)
    ) {
      setVehicleHist((prevState) => ({
        ...prevState,
        errMsgNextOdo: "Next service odometre invalid",
      }));
    } else {
      setVehicleHist((prevState) => ({
        ...prevState,
        errMsgNextOdo: "",
      }));
      const regoNumber = String(vehicleHist.regoNumber);
      database
        .doc(regoNumber)
        .collection("vehicleHistory")
        .doc(`${vehicleHist.attDate}`)
        .set({
          attDate: vehicleHist.attDate,
          regoNumber: vehicleHist.regoNumber,
          odoMetre: vehicleHist.odoMetre,
          wiperBlades: vehicleHist.wiperBlades,
          tyres: vehicleHist.tyres,
          carBulb: vehicleHist.carBulb,
          handBrake: vehicleHist.handBrake,
          brakeDrumsOrPads: vehicleHist.brakeDrumsOrPads,
          engineLight: vehicleHist.engineLight,
          oilMachine: vehicleHist.oilMachine,
          oilFilter: vehicleHist.oilFilter,
          fanOrCompressorBelts: vehicleHist.fanOrCompressorBelts,
          radiator: vehicleHist.radiator,
          battery: vehicleHist.battery,
          nextServiceDate: vehicleHist.nextServiceDate,
          nextServiceOdometre: vehicleHist.nextServiceOdometre,
        })
        .then(() => {
          setVehicleHist({
            attDate: "",
            regoNumber: "",
            odoMetre: 0,
            wiperBlades: "",
            tyres: "",
            carBulb: "",
            handBrake: "",
            brakeDrumsOrPads: "",
            engineLight: "",
            oilMachine: "",
            oilFilter: "",
            fanOrCompressorBelts: "",
            radiator: "",
            battery: "",
            nextServiceDate: "",
            nextServiceOdometre: 0,
            errMsgNextOdo: "",
          });
          setSuccessMsg("Thank you for submitting the form");
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const resetForm = () => {
    setVehicleHist({
      attDate: "",
      regoNumber: "",
      odoMetre: 0,
      wiperBlades: "",
      tyres: "",
      carBulb: "",
      handBrake: "",
      brakeDrumsOrPads: "",
      engineLight: "",
      oilMachine: "",
      oilFilter: "",
      fanOrCompressorBelts: "",
      radiator: "",
      battery: "",
      nextServiceDate: "",
      nextServiceOdometre: 0,
      errMsgNextOdo: "",
    });
  };

  return (
    <>
      <NavBar />
      <div className="upload-vec-hist">
        <h1 className="header">Register a Vehicle History</h1>
        <Form className="register-form" onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              <span className="form-label">Date (yyyy-mm-dd)</span>
            </Form.Label>
            <Col column sm="9">
              <span className="date">{changeDate()}</span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Rego No.<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                as="select"
                required
                name="regoNumber"
                className="dropdown"
                onChange={setValue}
                value={vehicleHist.regoNumber}
              >
                <option value="">Select Option</option>
                {vehicles.map((vehicle) => {
                  if (vehicle.active) {
                    return (
                      <option key={vehicle.id} value={vehicle.regoNumber}>
                        {vehicle.regoNumber}
                      </option>
                    );
                  }
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Odometre<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="odoMetre"
                type="number"
                value={vehicleHist.odoMetre}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Wiper Blades<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                as="select"
                required
                name="wiperBlades"
                className="dropdown"
                onChange={selectCondition}
                value={vehicleHist.wiperBlades}
              >
                <option value="">Select Option</option>
                <option value="Good">Good</option>
                <option value="Needs Change Both">Needs Change Both</option>
                <option value="Needs Change (Left Side)">
                  Needs Change (Left Side)
                </option>
                <option value="Needs Change (Right Side)">
                  Needs Change (Right Side)
                </option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Tyres<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("tyres", vehicleHist.tyres)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Car Bulb<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("carBulb", vehicleHist.carBulb)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Hand Brake<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("handBrake", vehicleHist.handBrake)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Brake Drums or Pads<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("brakeDrumsOrPads", vehicleHist.brakeDrumsOrPads)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Engine Light<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                as="select"
                required
                name="engineLight"
                className="dropdown"
                onChange={selectCondition}
                value={vehicleHist.engineLight}
              >
                <option value="">Select Option</option>
                <option value="On">On</option>
                <option value="Off">Off</option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Oil Machine<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("oilMachine", vehicleHist.oilMachine)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Oil Filter<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("oilFilter", vehicleHist.oilFilter)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Fan or Compressor Belts<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption(
                "fanOrCompressorBelts",
                vehicleHist.fanOrCompressorBelts
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Radiator<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("radiator", vehicleHist.radiator)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Battery<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {selectOption("battery", vehicleHist.battery)}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Next Service Date<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="nextServiceDate"
                type="date"
                value={vehicleHist.nextServiceDate}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Next Service Odometre<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="nextServiceOdometre"
                type="number"
                value={vehicleHist.nextServiceOdometre}
                onChange={setValue}
              />
              <div className="err-msg">{vehicleHist.errMsgNextOdo}</div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Notes
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
                value={vehicleHist.notes}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <div className="note">
            <span className="required">*</span> is required and cannot be blank
          </div>
          <Button
            type="button"
            onClick={() => {
              resetForm();
            }}
          >
            Reset
          </Button>
          <Button type="submit">Submit</Button>
        </Form>
        {successMsg && <Alert variant="success">{successMsg}</Alert>}
      </div>
      <Footer />
    </>
  );
}
