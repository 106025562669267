import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import { Form, Alert } from "react-bootstrap";
import NavBar from "../SideBar/NavBar";
import LogbookForm from "./LogbookForm";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Footer from "./Footer";
import * as XLSX from "xlsx";

import "antd/dist/antd.css";

export default function LogbookVehicle() {
  const [isFormActive, setIsFormActive] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [currentOption, setCurrentOption] = useState("");
  const [logbookList, setLogbookList] = useState([]);
  const [isErrExp, setIsErrExp] = useState("");
  const database = firebase.firestore().collection("vehicles");

  useEffect(() => {
    getVehicles();
    if (currentOption) {
      getLogbookVehicleData(currentOption);
    }
    if (isErrExp) {
      setTimeout(() => {
        setIsErrExp("");
      }, 3000);
    }
  }, [currentOption, isErrExp]);

  function getVehicles() {
    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const vehicles = [];
      querySnapshot.forEach((doc) => {
        vehicles.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicleList(vehicles);
      setLoading(false);
    });
  }

  function displayForm() {
    if (isFormActive) {
      return (
        <>
          <LogbookForm onActiveForm={handleForm} />
        </>
      );
    }
  }

  function selectRegoNumber(event) {
    event.preventDefault();
    setCurrentOption(event.target.value);
    if (event.target.value !== "") {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }

  const handleFileExp = () => {
    console.log(logbookList);
    if (logbookList.length > 0) {
      var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.json_to_sheet(logbookList);

      XLSX.utils.book_append_sheet(wb, ws, currentOption);

      XLSX.writeFile(wb, "Vehicle Logbook.xlsx");
    } else {
      setIsErrExp("Input all data before export the data");
    }
  };

  function getData() {
    if (!isFormActive) {
      return (
        <>
          <div className="header-container">
            <span
              className="icon-button"
              type="submit"
              hidden={isFormActive}
              onClick={() => {
                setIsFormActive(true);
              }}
            >
              Add new
            </span>
            <span
              hidden={isHidden}
              className="icon-button-additional"
              column
              sm="2"
              onClick={() => {
                handleFileExp();
              }}
            >
              Export File
            </span>
            <h1 className="logbook-header">Logbook History</h1>
          </div>

          <Form.Control
            as="select"
            required
            className="dropdown-logbook"
            onChange={selectRegoNumber}
          >
            <option value="">
              Select one of the rego numbers of the vehicles
            </option>
            {vehicleList.map((vehicle) => {
              if (vehicle.active) {
                return (
                  <option key={vehicle.id} value={vehicle.regoNumber}>
                    {vehicle.regoNumber +
                      " - " +
                      vehicle.model +
                      " - " +
                      vehicle.make}
                  </option>
                );
              }
            })}
          </Form.Control>

          <div hidden={!isHidden} style={{ textAlign: "center" }}>
            Please select a rego number
          </div>
          <Table hidden={isHidden}>
            <Thead>
              <Tr className="border">
                <Th className="table-header">No.</Th>
                <Th className="table-header">Start Date</Th>
                <Th className="table-header">End Date</Th>
                <Th className="table-header">Description</Th>
                <Th className="table-header">Start Odo</Th>
                <Th className="table-header">End Odo</Th>
                <Th className="table-header">Total Km</Th>
                <Th className="table-header">Date Input</Th>
              </Tr>
            </Thead>
            {displayData()}
          </Table>
        </>
      );
    }
  }

  function getLogbookVehicleData(currentOption) {
    setLogbookList([]);
    database
      .doc(currentOption)
      .collection("logbookVehicle")
      .orderBy("dateInput", "desc")
      .onSnapshot((querySnapshot) => {
        const logbooks = [];
        querySnapshot.forEach((doc) => {
          logbooks.push({ id: doc.id, ...doc.data() });
        });
        setLogbookList(logbooks);
      });
  }

  const displayData = () => {
    let number = 0;
    if (logbookList.length === 0) {
      return (
        <Tbody>
          <Tr>
            <Td colSpan="8" style={{ textAlign: "center" }}>
              This vehicle's records are not found
            </Td>
          </Tr>
        </Tbody>
      );
    } else {
      return (
        <Tbody>
          {logbookList.map((logbook) => {
            number++;
            return (
              <Tr className="border" key={logbook.id}>
                <Td className="table-content">{number}</Td>
                <Td className="table-content">{logbook.startDate}</Td>
                <Td className="table-content">{logbook.endDate}</Td>
                <Td className="table-content">{logbook.description}</Td>
                <Td className="table-content">{logbook.odoStart}</Td>
                <Td className="table-content">{logbook.odoEnd}</Td>
                <Td className="table-content">{logbook.totalKm}</Td>
                <Td className="table-content">{logbook.dateInput}</Td>
              </Tr>
            );
          })}
        </Tbody>
      );
    }
  };

  const handleForm = (isActive) => {
    setIsFormActive(isActive);
  };

  return (
    <>
      <NavBar />
      <div className="logbook-vehicle">
        {displayForm()}
        {isErrExp && <Alert variant="danger">{isErrExp}</Alert>}
        {getData()}
      </div>
      <Footer />
    </>
  );
}
