import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import NavBar from "../SideBar/NavBar";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Footer from "./Footer";

import "../../style_components/ShowAllVehicles.css";

const ShowAllVehicles = () => {
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  const database = firebase.firestore().collection("vehicles");

  // function getData() {
  //     setTimeout(() => {
  //     console.log('Our data is fetched');
  //     this.setState({
  //         data: 'Hello WallStreet'
  //     })
  //     }, 1000)
  //     console.log("GET DATA SHOW ALL VEHICLES");
  //     const dateYest = new Date();
  //     dateYest.setDate(dateYest.getDate()-1);
  //     console.log(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(dateYest));
  // }

  function getVehicles() {
    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicles(items);
      setLoading(false);
    });
  }

  //useEffect untuk semua page bisa d gabung menjadi satu di app.js kemudian di passing lewat props
  useEffect(() => {
    getVehicles();
  }, []);

  const displayData = () => {
    let number = 0;
    return (
      <Tbody>
        {vehicles.map((vehicle) => {
          if (vehicle.active) {
            number++;
            return (
              <Tr className="border" key={vehicle.id}>
                <Td className="table-content">{number}</Td>
                <Td className="table-content">{vehicle.regoNumber}</Td>
                <Td className="table-content">{vehicle.type}</Td>
                <Td className="table-content">{vehicle.make}</Td>
                <Td className="table-content">{vehicle.model}</Td>
                <Td className="table-content">{vehicle.color}</Td>
                <Td className="table-content">{vehicle.year}</Td>
                <Td className="table-content">{vehicle.vinNumber}</Td>
                <Td className="table-header">{vehicle.regoNumberExp}</Td>
                <Td className="table-content">{vehicle.notes}</Td>
                <Td className="table-content">
                  <span
                    className="table-button"
                    onClick={() => {
                      database.doc(vehicle.id).update({ active: false });
                    }}
                  >
                    Remove
                  </span>
                </Td>
              </Tr>
            );
          }
        })}
      </Tbody>
    );
  };

  function showVehicles() {
    return (
      <Table>
        <Thead>
          <Tr className="border">
            <Th className="table-header">No.</Th>
            <Th className="table-header">Rego</Th>
            <Th className="table-header">Type</Th>
            <Th className="table-header">Make</Th>
            <Th className="table-header">Model</Th>
            <Th className="table-header">Color</Th>
            <Th className="table-header">Year</Th>
            <Th className="table-header">VIN No.</Th>
            <Th className="table-header">Rego Exp</Th>
            <Th className="table-header">Notes</Th>
            <Th className="table-header">Action</Th>
          </Tr>
        </Thead>
        {displayData()}
      </Table>
    );
  }

  return (
    <>
      <NavBar />
      <div className="show-all-vehicles">
        <h1 className="header">Show All Vehicles</h1>
        {showVehicles()}
      </div>
      <Footer />
    </>
  );
};

export default ShowAllVehicles;
