import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-left">
        Build and Design by{" "}
        <Link to={{ pathname: "https://gracindo.com.au" }} target="_blank">
          Gracindo Pty Ltd
        </Link>
      </div>
      <div className="footer-right">Copyright &copy; 2022 - Ver 0.0.1</div>
    </div>
  );
}
