import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SideBarData } from "./SideBarData";
import "../../style_components/NavBar.css";
import { IconContext } from "react-icons";
import * as HeroIcons from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function NavBar() {
  const [sideBar, setSideBar] = useState(false);
  const [error, setError] = useState("");
  const { logout } = useAuth();
  const history = useHistory();

  const showSideBar = () => {
    setSideBar(!sideBar);
  };

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to logout");
    }
  }

  return (
    <>
      <IconContext.Provider value={{ fontSize: "2rem" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSideBar} />
          </Link>
        </div>
        <nav className={sideBar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSideBar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SideBarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    <span className="navbar-icon">{item.icon}</span>
                    <span className="navbar-menu">{item.title}</span>
                  </Link>
                </li>
              );
            })}
            <li className="nav-text">
              <Link onClick={handleLogout}>
                <span className="navbar-icon">
                  {<HeroIcons.HiOutlineLogout />}
                </span>
                <span className="navbar-menu">Logout</span>
              </Link>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}
