import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../components/contexts/AuthContext";

import "../App.css";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      console.log("success");
      await login(emailRef.current.value, passwordRef.current.value);
      // history.push("/");
      setMessage("Login successful");
    } catch {
      setLoading(true);
      console.log("error");
      setError("Failed to sign in");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    if (message) {
      setTimeout(() => {
        setMessage("");
        history.push("/");
      }, 3000);
    }
  }, [error, message]);

  return (
    <>
      <div className="header-login-form">
        <div className="logo">
          <img src="/Gracie Trans Logo.png" />
        </div>
        <h1 className="title">Vehicle Database</h1>
        {/* <div className="w-100" style={{ maxWidth: "400px" }}> */}
        <Card>
          <Card.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  disabled={loading}
                  ref={emailRef}
                  required
                />
              </Form.Group>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  disabled={loading}
                  ref={passwordRef}
                  required
                />
              </Form.Group>
              {console.log(loading)}
              <Button disabled={loading} className="w-100" type="submit">
                Login
              </Button>
            </Form>
            <div className="w-100 text-center mt-2">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          Need an account? <Link to="/signup">Sign Up</Link>
        </div>
        <div className="w-100 text-center mt-2">
          Update logbook? <Link to="/add-a-record">Add a record</Link>
        </div>
        {/* </div>  */}
      </div>
    </>
  );
}
