import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import DateObject from "react-date-object";
import NavBar from "../SideBar/NavBar";
import firebase from "../firebase";
import Footer from "./Footer";

import "../../style_components/FormStyle.css";

const UpdateAVehicle = () => {
  const [currentDetail, setCurrentDetail] = useState({
    regoNumber: "",
    make: "",
    model: "",
    color: "",
    type: "",
    year: 0,
    vinNumber: "",
    regoNumberExp: "",
    notes: "",
    active: true,
    dateRegister: "",
  });
  const [isExp, setIsExp] = useState("");
  const [isVehicleNew, setIsVehicleNew] = useState(false);
  const [isFormHidden, setIsFormHidden] = useState(true);

  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);

  let date = "";

  const database = firebase.firestore().collection("vehicles");

  useEffect(() => {
    getVehicles();
    changeDate();
  }, []);

  function getVehicles() {
    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicles(items);
      setLoading(false);
    });
  }

  const setValue = (event) => {
    const {
      target: { name, value },
    } = event;
    console.log(event.target.value);
    // setCurrentDetail(prevState => ({ ...prevState, [name] : value }));
  };

  function changeDate() {
    let dateToday = new DateObject();
    date = dateToday.format("YYYY-MM-DD");
    return dateToday.format("YYYY-MM-DD");
  }

  function selectRegoNumber(event) {
    //splitting the value from select tag
    let selectedVehicle = event.target.value.split(",");

    setCurrentDetail({
      regoNumber: selectedVehicle[0],
      make: selectedVehicle[1],
      model: selectedVehicle[2],
      color: selectedVehicle[3],
      type: selectedVehicle[4],
      year: selectedVehicle[5],
      vinNumber: selectedVehicle[6],
      regoNumberExp: selectedVehicle[7],
      notes: selectedVehicle[8],
      active: selectedVehicle[9],
      dateRegister: selectedVehicle[10],
    });

    if (event.target.value !== null || event.target.value !== "") {
      setIsFormHidden(false);
    } else {
      setIsFormHidden(true);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (currentDetail.notes === "") {
      setCurrentDetail((prevState) => ({ ...prevState, notes: "-" }));
    }

    if (isVehicleNew) {
      database
        .doc(currentDetail.regoNumber)
        .set({
          dateRegister: date,
          regoNumber: currentDetail.regoNumber,
          make: currentDetail.make,
          model: currentDetail.model,
          color: currentDetail.color,
          type: currentDetail.type,
          year: currentDetail.year,
          vinNumber: currentDetail.vinNumber,
          regoNumberExp: currentDetail.regoNumberExp,
          notes: currentDetail.notes,
          active: currentDetail.active,
        })
        .then(() => {})
        .catch((err) => {
          console.error(err);
        });
    }
  };

  // const handleEdit = () => {
  //     setIsEditRegoExp(true);
  //     setIsCancelButtonHidden(false);
  // }

  // const handleCancel = () => {
  //     setIsEditRegoExp(false);
  //     setIsCancelButtonHidden(true);
  // }

  // const isDateUpdate = () => {
  //     setIsEditRegoExp(false);
  //     setIsCancelButtonHidden(true);
  // }

  // function displayExpDate() {
  //     if(isEditRegoExp) {
  //         return(
  //             <>
  //                 <Form.Control
  //                     required
  //                     name="regoNumberExp"
  //                     type="date"
  //                     value={currentDetail.regoNumberExp}
  //                     onChange={setValue}
  //                     disabled={isCancelButtonHidden}
  //                 />
  //             </>
  //         )
  //     } else {
  //         return (
  //             <>
  //                 {currentDetail.regoNumberExp}
  //             </>
  //         )
  //     }
  // }

  return (
    <>
      <NavBar />
      <div className="register-new-vec">
        <h1 className="header">Update a Vehicle</h1>
        <Form.Control
          as="select"
          required
          className="dropdown"
          onChange={selectRegoNumber}
        >
          <option value="">
            Select one of the rego number of the vehicles
          </option>
          {vehicles.map((vehicle) => {
            if (vehicle.active) {
              return (
                <option
                  key={vehicle.id}
                  value={[
                    `${vehicle.regoNumber}`,
                    `${vehicle.make}`,
                    `${vehicle.model}`,
                    `${vehicle.color}`,
                    `${vehicle.type}`,
                    `${vehicle.year}`,
                    `${vehicle.vinNumber}`,
                    `${vehicle.regoNumberExp}`,
                    `${vehicle.notes}`,
                    `${vehicle.active}`,
                    `${vehicle.dateRegister}`,
                  ]}
                >
                  {vehicle.regoNumber +
                    " - " +
                    vehicle.model +
                    " - " +
                    vehicle.make}
                </option>
              );
            }
          })}
        </Form.Control>

        <div hidden={!isFormHidden} style={{ textAlign: "center" }}>
          Please select a rego number
        </div>

        <Form
          className="register-form"
          onSubmit={handleSubmit}
          hidden={isFormHidden}
        >
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              <span className="form-label">Date (yyyy-mm-dd)</span>
            </Form.Label>
            <Col column sm="9">
              <span className="date">{changeDate()}</span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Date Register
            </Form.Label>
            <Col column sm="9">
              {currentDetail.dateRegister}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Rego No.
            </Form.Label>
            <Col column sm="9">
              {currentDetail.regoNumber}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Make
            </Form.Label>
            <Col column sm="9">
              {currentDetail.make}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Model
            </Form.Label>
            <Col column sm="9">
              {currentDetail.model}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Color
            </Form.Label>
            <Col column sm="9">
              {currentDetail.color}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Type
            </Form.Label>
            <Col column sm="9">
              {currentDetail.type}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Year
            </Form.Label>
            <Col column sm="9">
              {currentDetail.year}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              VIN Number
            </Form.Label>
            <Col column sm="9">
              {currentDetail.vinNumber}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Rego Expired Date<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              {/* <> */}
              <Form.Control
                required
                name="regoNumberExp"
                type="date"
                value={isExp}
                onChange={setValue}
              />
              {/* </> */}
              {/* {displayExpDate()}
                            <span
                                className="button-inside-form"
                                type="submit"
                                onClick={handleEdit}
                                hidden={!isCancelButtonHidden}
                            >
                                Edit
                            </span>
                             <span
                                className="button-inside-form"
                                type="submit"
                                onClick={isDateUpdate}
                                hidden={isCancelButtonHidden}
                            >
                                Submit
                            </span>
                            <span
                                className="button-inside-form"
                                type="submit"
                                hidden={isCancelButtonHidden}
                                onClick={handleCancel}
                            >
                                Cancel
                            </span> */}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Notes
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                as="textarea"
                name="notes"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
                value={currentDetail.notes}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <div className="note">
            <span className="required">*</span> is required and cannot be blank
            <br />
            <span className="required">
              Date Register means a date that a vehicle has been uploaded to our
              database
            </span>
          </div>

          <Button type="submit">Update</Button>
        </Form>
      </div>
      <Footer />
    </>
  );
};

export default UpdateAVehicle;
