import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import NavBar from "../SideBar/NavBar";
import { Form } from "react-bootstrap";
import Footer from "./Footer";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import "../../style_components/ShowVehicleHist.css";

const ShowVehicleHist = () => {
  const [vehicleList, setVehicleList] = useState([]);
  const [histList, setHistList] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [displayDetails, setDisplayDetails] = useState(false);
  const [currentOption, setCurrentOption] = useState("");
  const [istableHidden, setIsTableHidden] = useState(true);

  const database = firebase.firestore().collection("vehicles");

  useEffect(() => {
    getVehicles();
    if (currentOption) {
      getHistList(currentOption);
    }
  }, [currentOption]);

  function getVehicles() {
    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicleList(items);
      setLoading(false);
    });
  }

  function getHistList(currentOption) {
    setHistList([]);
    database
      .doc(currentOption)
      .collection("vehicleHistory")
      .onSnapshot((querySnapshot) => {
        const carHistory = [];
        querySnapshot.forEach((doc) => {
          carHistory.push({ id: doc.id, ...doc.data() });
        });
        setHistList(carHistory);
      });
  }

  function selectRegoNumber(event) {
    event.preventDefault();
    setCurrentOption(event.target.value);
    if (event.target.value !== "") {
      setIsTableHidden(false);
    } else {
      setIsTableHidden(true);
    }
  }

  function getVehicleHistories() {
    return (
      <>
        <Form.Control
          as="select"
          required
          className="dropdown-logbook"
          onChange={selectRegoNumber}
        >
          <option value="">
            Select one of the rego numbers of the vehicles
          </option>
          {vehicleList.map((vehicle) => {
            if (vehicle.active) {
              return (
                <option key={vehicle.id} value={vehicle.regoNumber}>
                  {vehicle.regoNumber +
                    " - " +
                    vehicle.model +
                    " - " +
                    vehicle.make}
                </option>
              );
            }
          })}
        </Form.Control>
        <div hidden={!istableHidden} style={{ textAlign: "center" }}>
          Please select a rego number
        </div>

        <Table hidden={istableHidden}>
          <Thead>
            <Tr className="border">
              <Th className="table-header">No.</Th>
              <Th className="table-header">Odo Metre</Th>
              <Th className="table-header">Wiper Blades</Th>
              <Th className="table-header">Tyres</Th>
              <Th className="table-header">Bulb</Th>
              <Th className="table-header">HandBrake</Th>
              <Th className="table-header">Brake Drums or Pads</Th>
              <Th className="table-header">Engine Light</Th>
              <Th className="table-header">Oil Machine</Th>
              <Th className="table-header">Oil Filter</Th>
              <Th className="table-header">Belts</Th>
              <Th className="table-header">Radiator</Th>
              <Th className="table-header">Battery</Th>
              <Th className="table-header">Next Service Date</Th>
              <Th className="table-header">Next Service Odo Metre</Th>
            </Tr>
          </Thead>
          {displayData()}
        </Table>
      </>
    );
  }

  const displayData = () => {
    let number = 0;
    if (histList.length === 0) {
      return (
        <Tbody>
          <Tr>
            <Td colSpan="15" style={{ textAlign: "center" }}>
              This vehicle's histories are not found
            </Td>
          </Tr>
        </Tbody>
      );
    } else {
      return (
        <Tbody>
          {histList.map((carHistory) => {
            number++;
            return (
              <Tr className="border" key={carHistory.id}>
                <Td className="table-content">{number}</Td>
                <Td className="table-content">{carHistory.odoMetre}</Td>
                <Td className="table-content">{carHistory.wiperBlades}</Td>
                <Td className="table-content">{carHistory.tyres}</Td>
                <Td className="table-content">{carHistory.carBulb}</Td>
                <Td className="table-content">{carHistory.handBrake}</Td>
                <Td className="table-content">{carHistory.brakeDrumsOrPads}</Td>
                <Td className="table-content">{carHistory.engineLight}</Td>
                <Td className="table-content">{carHistory.oilMachine}</Td>
                <Td className="table-content">{carHistory.oilFilter}</Td>
                <Td className="table-content">
                  {carHistory.fanOrCompressorBelts}
                </Td>
                <Td className="table-content">{carHistory.radiator}</Td>
                <Td className="table-content">{carHistory.battery}</Td>
                <Td className="table-content">{carHistory.nextServiceDate}</Td>
                <Td className="table-content">
                  {carHistory.nextServiceOdometre}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      );
    }
  };
  return (
    <>
      <NavBar />
      <div className="show-vehicle-history">
        <h1 className="header">Vehicle History</h1>
        {/* <div className="err-msg" style={{ textAlign: "center" }}>
          Sorry for the inconvenience, this page is under development
        </div> */}
        {getVehicleHistories()}
      </div>
      <Footer />
    </>
  );
};

export default ShowVehicleHist;
