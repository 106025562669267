import React from "react";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import * as BSIcons from "react-icons/bs";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";

export const SideBarData = [
  {
    title: "Show All Vehicles",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
  },
  // {
  //   title: 'Update A Vehicle',
  //   path: '/update-a-vehicle',
  //   icon: <MdIcons.MdUpdate />,
  //   cName: 'nav-text',
  // },
  {
    title: "Register New Vehicle",
    path: "/register-new-vehicle",
    icon: <AiIcons.AiOutlineCar />,
    cName: "nav-text",
  },
  {
    title: "Add Vehicle History",
    path: "/add-vehicle-history",
    icon: <CgIcons.CgFileDocument />,
    cName: "nav-text",
  },
  {
    title: "Show Vehicle History",
    path: "/vehicle-history",
    icon: <BiIcons.BiBook />,
    cName: "nav-text",
  },
  {
    title: "Logbook Vehicle",
    path: "/logbook-vehicle",
    icon: <BSIcons.BsBookmarkPlusFill />,
    cName: "nav-text",
  },
  // {
  //   title: 'Attendance Form',
  //   path: '/attendance-form',
  //   icon: <FaIcons.FaWpforms />,
  //   cName: 'nav-text',
  // },
  // {
  //   title: 'My Profile',
  //   path: '/my-profile',
  //   icon: <CgIcons.CgProfile />,
  //   cName: 'nav-text',
  // },
  // {
  //   title: 'Working History',
  //   path: '/working-history',
  //   icon: <FaIcons.FaFileInvoiceDollar />,
  //   cName: 'nav-text',
  // }
];
