import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import DateObject from "react-date-object";
import NavBar from "../SideBar/NavBar";
import firebase from "../firebase";
import Footer from "./Footer";

import "../../style_components/FormStyle.css";

export default function RegisterNewVec() {
  const [newVehicle, setNewVehicle] = useState({
    regoNumber: "",
    make: "",
    model: "",
    color: "",
    type: "",
    year: 0,
    vinNumber: "",
    regoNumberExp: "",
    notes: "",
    active: true,
  });
  const [isVehicleRegistered, setIsVehicleRegistered] = useState(true);
  const [errMsg, setErrMsg] = useState("");

  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isHidden, setIsHidden] = useState(false);

  let date = "";

  const database = firebase.firestore().collection("vehicles");

  useEffect(() => {
    getVehicles();
    changeDate();
  }, []);

  function getVehicles() {
    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicles(items);
      setLoading(false);
    });
  }

  const setValue = (event) => {
    const {
      target: { name, value },
    } = event;
    setNewVehicle((prevState) => ({ ...prevState, [name]: value }));
  };

  function changeDate() {
    let dateToday = new DateObject();
    date = dateToday.format("YYYY-MM-DD");
    return dateToday.format("YYYY-MM-DD");
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    if (newVehicle.notes === "") {
      setNewVehicle((prevState) => ({ ...prevState, notes: "-" }));
    }

    vehicles.map((vehicleDatabase) => {
      let count = 0;
      if (vehicleDatabase.regoNumber === newVehicle.regoNumber) {
        count = count + 1;
      }
      if (count === 0) {
        setIsVehicleRegistered(false);
      } else {
        setIsVehicleRegistered(true);
        setErrMsg(
          "This vehicle has been registered to our database, please contact admin"
        );
        console.log(newVehicle.regoNumber);
      }
    });

    if (!isVehicleRegistered) {
      database
        .doc(newVehicle.regoNumber)
        .set({
          dateRegister: date,
          regoNumber: newVehicle.regoNumber,
          make: newVehicle.make,
          model: newVehicle.model,
          color: newVehicle.color,
          type: newVehicle.type,
          year: newVehicle.year,
          vinNumber: newVehicle.vinNumber,
          regoNumberExp: newVehicle.regoNumberExp,
          notes: newVehicle.notes,
          active: newVehicle.active,
        })
        .then(() => {
          setNewVehicle({
            regoNumber: "",
            make: "",
            model: "",
            color: "",
            type: "",
            year: 0,
            vinNumber: "",
            notes: "",
            regoNumberExp: "",
            active: true,
          });
          setIsVehicleRegistered(true);
          setErrMsg("");
          setSuccessMsg("Thank you for completing the form");
          setIsHidden(true);
          setTimeout(() => {
            setSuccessMsg("");
            setIsHidden(false);
          }, 5000);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <NavBar />
      <div className="register-new-vec">
        <h1 className="header">Register a New Vehicle</h1>
        <Form
          className="register-form"
          onSubmit={handleSubmit}
          hidden={isHidden}
        >
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              <span className="form-label">Date (yyyy-mm-dd)</span>
            </Form.Label>
            <Col column sm="9">
              <span className="date">{changeDate()}</span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Rego No.<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="regoNumber"
                type="text"
                value={newVehicle.regoNumber}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Make<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="make"
                type="text"
                value={newVehicle.make}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Model<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="model"
                type="text"
                value={newVehicle.model}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Color<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="color"
                type="text"
                value={newVehicle.color}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Type<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="type"
                type="text"
                value={newVehicle.type}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Year<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="year"
                type="number"
                value={newVehicle.year}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              VIN Number<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="vinNumber"
                type="text"
                value={newVehicle.vinNumber}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Rego Expired Date<span className="required">*</span>
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                required
                name="regoNumberExp"
                type="date"
                value={newVehicle.regoNumberExp}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="3">
              Notes
            </Form.Label>
            <Col column sm="9">
              <Form.Control
                as="textarea"
                name="notes"
                placeholder="Leave a comment here"
                style={{ height: "100px" }}
                value={newVehicle.notes}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <div className="note">
            <span className="required">*</span> is required and cannot be blank
          </div>

          <div className="err-msg">{errMsg}</div>

          <Button type="submit">Submit</Button>
        </Form>
        {successMsg && <Alert variant="success">{successMsg}</Alert>}
      </div>
      <Footer />
    </>
  );
}
