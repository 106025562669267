import React, { useState, useEffect } from "react";
import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import DateObject from "react-date-object";
import firebase from "../firebase";

import "../../style_components/FormStyle.css";
import "antd/dist/antd.css";

const LogbookForm = (props) => {
  const [vehicles, setVehicles] = useState([]);
  const [regoNumber, setRegoNumber] = useState("");
  const [data, setData] = useState({
    startDate: "",
    endDate: "",
    description: "",
    dateInput: "",
  });
  const [odoStart, setOdoStart] = useState(0);
  const [odoEnd, setOdoEnd] = useState(0);
  const [totalKm, setTotalKm] = useState(0);
  const [disabledOdo, setDisabledOdo] = useState(true);
  const [errOdoStart, setErrOdoStart] = useState("");
  const [errCal, setErrCal] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isHidden, setIsHidden] = useState(false);

  const database = firebase.firestore().collection("vehicles");

  function getVehicles() {
    setLoading(true);
    database.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, isExpand: "Expand", ...doc.data() });
      });
      setVehicles(items);
      setLoading(false);
    });
  }

  useEffect(() => {
    getVehicles();
  }, []);

  // useEffect(() => {
  //     //get database Vehicle
  //     //get database person
  //     console.log("GET VEHICLE DATA");
  //     console.log("GET DRIVERS DATA");
  //     return () => {

  //     }
  // }, [])

  function changeDate() {
    let date = new DateObject();
    return date.format("YYYY-MM-DD");
  }

  const updateRegoNumber = (event) => {
    setRegoNumber(event.target.value);
  };

  const setValue = (event) => {
    const {
      target: { name, value },
    } = event;
    setData((prevState) => ({ ...prevState, [name]: value }));
  };

  const inputOdoStart = (event) => {
    event.preventDefault();
    setOdoStart(event.target.value);
    if (event.target.value < 0 || event.target.value === null) {
      setDisabledOdo(true);
      setErrOdoStart("Invalid Odometer");
    } else {
      setErrOdoStart("");
      setDisabledOdo(false);
    }
  };

  const inputOdoEnd = (event) => {
    event.preventDefault();
    setOdoEnd(event.target.value);
    let total = event.target.value - odoStart;
    if (total > 0 && total !== event.target.value) {
      setTotalKm(total);
      setErrCal("");
      setErrMsg("");
    } else {
      setErrCal("Invalid Odometer");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (errCal !== "" && errOdoStart === "") {
      setErrMsg("Input data is invalid");
    } else {
      database
        .doc(regoNumber)
        .collection("logbookVehicle")
        .doc(`${data.startDate}`)
        .set({
          startDate: data.startDate,
          endDate: data.endDate,
          description: data.description,
          odoStart: odoStart,
          odoEnd: odoEnd,
          totalKm: totalKm,
          dateInput: data.dateInput,
        })
        .then(() => {
          setRegoNumber("");
          setData({
            startDate: "",
            endDate: "",
            description: "",
            dateInput: "",
          });
          setOdoStart(0);
          setOdoEnd(0);
          setTotalKm(0);
          setSuccessMsg("Thank you for completing the form");
          setIsHidden(true);
          setTimeout(() => {
            setSuccessMsg("");
            setIsHidden(false);
          }, 5000);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const resetForm = () => {
    setRegoNumber("");
    setData({
      startDate: "",
      endDate: "",
      description: "",
      dateInput: "",
    });
    setOdoStart(0);
    setOdoEnd(0);
    setTotalKm(0);
  };

  return (
    <>
      <div className="logbook-vehicle">
        <div className="header-container">
          <span
            hidden={isHidden}
            className="icon-button"
            column
            sm="2"
            onClick={() => {
              props.onActiveForm(false);
            }}
          >
            Cancel
          </span>
          <h1 className="logbook-header">Logbook Form</h1>
        </div>
        <Form
          className="register-form"
          onSubmit={handleSubmit}
          hidden={isHidden}
        >
          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Date (yyyy-mm-dd)
            </Form.Label>
            <Col column sm="8">
              <span className="date">{changeDate()}</span>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Rego Number<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                as="select"
                required
                name="regoNumber"
                className="dropdown"
                onChange={updateRegoNumber}
                value={regoNumber}
              >
                <option value="">Select Rego Number</option>
                {vehicles.map((vehicle) => {
                  if (vehicle.active) {
                    return (
                      <option key={vehicle.id} value={vehicle.regoNumber}>
                        {vehicle.regoNumber}
                      </option>
                    );
                  }
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Start Date<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                required
                name="startDate"
                type="date"
                value={data.startDate}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              End Date<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                required
                name="endDate"
                type="date"
                value={data.endDate}
                onChange={setValue}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Description<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                required
                name="description"
                type="text"
                value={data.description}
                onChange={setValue}
                placeholder="e.g. Richmond, Cremorne, Balaclava"
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Start Odometer<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                required
                name="odoStart"
                type="number"
                value={odoStart}
                onChange={inputOdoStart}
              />
            </Col>
            <div className="err-msg">{errOdoStart}</div>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              End Odometer<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                required
                name="odoEnd"
                type="number"
                value={odoEnd}
                disabled={disabledOdo}
                onChange={inputOdoEnd}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Total Kilometres<span className="required"></span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                name="totalKm"
                type="number"
                value={totalKm}
                disabled={true}
              />
            </Col>
            <div className="err-msg">{errCal}</div>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Form.Label className="form-label" column sm="4">
              Date Input<span className="required">*</span>
            </Form.Label>
            <Col column sm="8">
              <Form.Control
                required
                name="dateInput"
                type="date"
                value={data.dateInput}
                onChange={setValue}
              />
            </Col>
          </Form.Group>
          <Button type="submit">Submit</Button>
          <Button
            type="reset"
            onClick={() => {
              resetForm();
            }}
          >
            Reset
          </Button>
        </Form>
        {errMsg && <Alert variant="danger">{errMsg}</Alert>}
        {successMsg && <Alert variant="success">{successMsg}</Alert>}
      </div>
    </>
  );
};

export default LogbookForm;
