import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import ShowAllVehicles from "./components/Pages/ShowAllVehicles";
import UpdateAVehicle from "./components/Pages/UpdateAVehicle";
import RegisterNewVec from "./components/Pages/RegisterNewVec";
import AddVecHist from "./components/Pages/AddVecHist";
import ShowVehicleHist from "./components/Pages/ShowVehicleHist";
import LogbookVehicle from "./components/Pages/LogbookVehicle";
import AddLogbook from "./components/AddLogbook";
import { Container } from "react-bootstrap";
import { AuthProvider } from "./components/contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

export default function App() {
  return (
    <>
      <div className="flex-wrapper">
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={ShowAllVehicles} />
              <PrivateRoute
                exact
                path="/update-a-vehicle"
                component={UpdateAVehicle}
              />
              <PrivateRoute
                exact
                path="/register-new-vehicle"
                component={RegisterNewVec}
              />
              <PrivateRoute
                exact
                path="/add-vehicle-history"
                component={AddVecHist}
              />
              <PrivateRoute
                exact
                path="/vehicle-history"
                component={ShowVehicleHist}
              />
              <PrivateRoute
                exact
                path="/logbook-vehicle"
                component={LogbookVehicle}
              />
              <Container
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: "100vh" }}
              >
                <Route path="/signup" component={SignUp} />
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/add-a-record" component={AddLogbook} />
              </Container>
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    </>
  );
}
